import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section5 from "./components/Section4";
const about = () => {
    return(
        <>
            <Section1/>
            <Section3/>
            <Section2/>
            <Section5/>
        </>
    )
}
export default about